import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../components/PaymentForm";

const PUBLIC_KEY =
  "pk_live_51Jz6GOFisb5seqTFt0BrwjZAhlAeam4uJdP0ZbF7aGZPOiRK3ll5KGKMkNFctkB9om8khrhfKNilDEAnitwX12yl00lFffH0Dg";

const stripePromise = loadStripe(PUBLIC_KEY);

const CheckoutTravel = (props) => {
  const [shippingCheck, setShippingCheck] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntent, setPaymentIntent] = useState("");
  const [stripeLoading, setStripeLoading] = useState(false);

  const energyInfo = props.usage;

  console.log(props.totalUsage);
  console.log(props.finalOffset);
  console.log(props.isRoundTrip);

  var finalPrice = "";
  var TravelOffset = "";

  switch (props.isRoundTrip) {
    case false:
      var finalPrice = Number(
        props.finalOffset + props.finalOffset * 0.03
      ).toFixed(2);
      var TravelOffset = "Go Green Credits Carbon Offset Travel One Way";
      break;
    case true:
      var finalPrice = Number(
        props.roundTripOffset + props.roundTripOffset * 0.03
      ).toFixed(2);
      var TravelOffset = "Go Green Credits Carbon Offset Travel Round Trip";
      break;
  }

  console.log(finalPrice);

  const stripeCost = finalPrice * 100;

  useEffect(() => {
    getPaymentIntent();
  }, []);

  const getPaymentIntent = async () => {
    // createEnrollmentEntry(function () {});//

    const intent = await fetch("/.netlify/functions/stripe-payment-travel", {
      //http://localhost:8888/.netlify/functions/stripe-payment-travel///
      //https://ugecredits.com/api/stripe-payment-travel///
      //https://ugecredits.com/.netlify/functions/stripe-payment-travel//
      // http://localhost:8888/.netlify/functions/stripe-payment-travel
      method: "POST",
      body: JSON.stringify({
        amount: Math.round(stripeCost),
        customer: props.customer,
        recurring: TravelOffset,
      }),
    });

    const { paymentIntent } = await intent.json();
    setClientSecret(paymentIntent.client_secret);
    setPaymentIntent(paymentIntent);
    setStripeLoading(false);
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    appearance: {
      theme: "minimal",
      variables: {
        colorBackground: "#bdeaba",
        colorText: "#07300d",
        colorDanger: "#df1b41",
        spacingUnit: "5px",
        borderRadius: "4px",
        // See all possible variables below
      },
    },
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  console.log(paymentIntent);
  console.log(clientSecret);
  console.log(stripeCost);

  if (typeof window === "undefined") {
    return <div></div>;
  } else {
    return (
      <div class="checkout">
        <div class="hero mt-10 md:mt-20 items-center pb-10 px-4 md:px-0">
          <div class="mx-auto max-w-7xl">
            <div class="md:flex justify-between items-start">
              <div class="md:w-1/2">
                {" "}
                <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  Payment Details
                </h1>
                <p class="mt-3 text-base text-gray-600 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                  Thank you for using our calculator to determine your carbon
                  footprint. Your calculated annual carbon footprint is
                  displayed below. Go carbon neutral by offsetting 100% of your
                  calculated carbon footprint. Your decision to offset enables
                  Callective Energy to eliminate potent greenhouse gases across
                  the globe.
                </p>{" "}
                <div class="w-full mt-12  pb-4 space-y-6 ">
                  <dl class="bg-emerald-100 mb-12 bg-opacity-50 p-8 rounded-lg  mt-8 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                    <div>
                      <p class="font-medium text-base text-gray-900">
                        Customer Details
                      </p>
                      <dd class="mt-2">
                        <address class="not-italic">
                          {props.customer.companyName === "" ? (
                            <span class="block">
                              {props.customer.firstName +
                                " " +
                                props.customer.lastName}
                            </span>
                          ) : (
                            <span class="block">
                              {props.customer.companyName}
                            </span>
                          )}
                          <span class="mt-1 block">{props.customer.email}</span>
                          <span class="mt-1 block">
                            {" "}
                            {formatPhoneNumber(props.customer.phone)}
                          </span>
                        </address>
                      </dd>
                    </div>{" "}
                    <div>
                      <p class="font-medium text-base text-gray-900">
                        Billing Details
                      </p>
                      <dd class="mt-2">
                        <address class="not-italic">
                          <span class="block">
                            {props.customer.billingAddress +
                              " " +
                              props.customer.billingSuite}
                          </span>
                          <span class="mt-1 block">
                            {props.customer.billingCity +
                              "," +
                              " " +
                              props.customer.billingState +
                              " " +
                              props.customer.billingZip}
                          </span>
                        </address>
                      </dd>
                    </div>{" "}
                  </dl>

                  <dl class="text-sm font-medium text-gray-500 space-y-10 border-t border-gray-200 pt-10">
                    <div class="flex justify-between items-center">
                      {props.isRoundTrip === false ? (
                        <p class="text-base">
                          <p>
                            {" "}
                            <span class="text-orange-400 font-medium">
                              One Way{" "}
                            </span>
                            Carbon Offset
                          </p>
                          <p class="pt-2">
                            {" "}
                            <span class="text-emerald-400 font-medium">
                              {props.totalUsage.toFixed(2)}
                            </span>{" "}
                            Tons of Carbon Offset
                          </p>
                        </p>
                      ) : (
                        <p class="text-base">
                          <p>
                            {" "}
                            <span class="text-blue-400 font-medium">
                              Round Trip{" "}
                            </span>
                            Carbon Offset
                          </p>
                          <p class="pt-2">
                            {" "}
                            <span class="text-emerald-400 font-medium">
                              {props.roundTrip.toFixed(2)}
                            </span>{" "}
                            Tons of Carbon Offset
                          </p>
                        </p>
                      )}

                      {props.isRoundTrip === false ? (
                        <dd class="text-gray-900">
                          <span class="font-bold text-gray-600 text-lg">
                            ${Number(props.finalOffset.toFixed(2))}
                          </span>
                        </dd>
                      ) : (
                        <dd class="text-gray-900">
                          <span class="font-bold text-gray-600 text-lg">
                            ${Number(props.roundTripOffset.toFixed(2))}
                          </span>
                        </dd>
                      )}
                    </div>
                    <div class="border-t pt-10 flex justify-between items-center">
                      <p>
                        <p class="text-base"> Transaction Fee</p>
                      </p>

                      {props.isRoundTrip === false ? (
                        <dd class="text-gray-900">
                          <span class="font-bold text-gray-600 text-lg">
                            ${Number(props.finalOffset * 0.03).toFixed(2)}
                          </span>
                        </dd>
                      ) : (
                        <dd class="text-gray-900">
                          <span class="font-bold text-gray-600 text-lg">
                            ${Number(props.roundTripOffset * 0.03).toFixed(2)}
                          </span>
                        </dd>
                      )}
                    </div>
                    <div class="border-t pt-10 flex justify-between items-center">
                      <p>
                        <p class="text-base"> Total</p>
                      </p>

                      {props.isRoundTrip === false ? (
                        <dd class="text-gray-900">
                          <span class="font-bold text-emerald-600 text-lg">
                            $
                            {Number(
                              props.finalOffset + props.finalOffset * 0.03
                            ).toFixed(2)}
                          </span>
                        </dd>
                      ) : (
                        <dd class="text-gray-900">
                          <span class="font-bold text-emerald-600 text-lg">
                            $
                            {Number(
                              props.roundTripOffset +
                                props.roundTripOffset * 0.03
                            ).toFixed(2)}
                          </span>
                        </dd>
                      )}
                    </div>
                  </dl>
                </div>{" "}
              </div>
              <div class="checkout pl-10 md:w-2/5 sticky top-5 ">
                {clientSecret != "" ? (
                  <Elements stripe={stripePromise} options={options}>
                    <PaymentForm
                      energy={props.usage}
                      data={props.data}
                      customer={props.customer}
                      totalUsage={props.totalUsage}
                      paymentIntent={paymentIntent}
                    />{" "}
                  </Elements>
                ) : (
                  <div class=" mx-auto py-20 max-w-7xl px-4 text-center">
                    <div>
                      <svg
                        class="mx-auto animate-spin-slow mb-6 w-1/4 md:w-2/12"
                        fill="#ea6e0c"
                        viewBox="0 0 24 24"
                      >
                        <path d="M13 0c3.667.305 6.863 2.26 8.851 5.129l-1.746 1.013c-1.634-2.273-4.182-3.84-7.105-4.133v-2.009zm-11 12c0-1.47.324-2.863.891-4.122l-1.737-1.007c-.733 1.558-1.154 3.292-1.154 5.129 0 1.837.421 3.571 1.153 5.129l1.738-1.008c-.567-1.259-.891-2.651-.891-4.121zm20 0c0 1.47-.324 2.863-.891 4.122l1.738 1.007c.732-1.558 1.153-3.292 1.153-5.129s-.421-3.571-1.153-5.129l-1.738 1.007c.567 1.259.891 2.652.891 4.122zm-1.895 5.858c-1.634 2.273-4.182 3.84-7.105 4.133v2.009c3.667-.305 6.863-2.26 8.851-5.129l-1.746-1.013zm-16.21-11.717c1.634-2.272 4.183-3.839 7.105-4.132v-2.009c-3.667.305-6.862 2.259-8.851 5.128l1.746 1.013zm7.105 15.85c-2.923-.293-5.471-1.86-7.105-4.133l-1.746 1.013c1.988 2.87 5.184 4.824 8.851 5.129v-2.009z" />
                      </svg>

                      <h3 class="text-4xl leading-tight font-extrabold text-main-orange tracking-tight ">
                        Loading Stripe
                      </h3>
                      <p class="mt-5 sm:px-20 mx-auto text-md text-gray-500">
                        If this section doesn't refresh in a few seconds come
                        back later as we may be experiencing issues...
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CheckoutTravel;
