import React, { useState, useRef } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { Button, ButtonGroup, SkeletonText } from "@chakra-ui/react";

const Travel1 = (props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_API_KEY,
    libraries: ["places"],
  });

  console.log(useJsApiLoader);

  const restrictions = {
    country: "us",
  };
  //
  const options = {
    strictBounds: true,
  };

  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    props.passenger(value);
  };

  const originRef = useRef();
  const destiantionRef = useRef();

  if (!isLoaded) {
    return <SkeletonText />;
  }

  async function calculateRoute() {
    if (originRef.current.value === "" || destiantionRef.current.value === "") {
      return;
    }

    props.setisFormComplete(true);

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destiantionRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    props.distanceSet(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  return (
    <div class="travel 1">
      <div class="hero mt-10 md:mt-20 items-center pb-10 px-4 md:px-0">
        <div class="mx-auto max-w-7xl">
          <div class="md:flex justify-between items-start">
            <div class="md:w-1/2">
              <nav class="opacity-100 flex" aria-p="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div>
                      <a href="/" class="text-white hover:text-gray-200">
                        <svg
                          class="flex-shrink-0 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span class="sr-only">Home</span>
                      </a>
                    </div>
                  </li>

                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Home1";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        Travel
                      </button>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                Travel
              </h1>
              <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                Enter the details of your trip then press calculate to determine
                your carbon offset!
              </p>{" "}
              <div class="energy options mt-5 border border-blue-200 bg-blue-100 pt-6 pb-6 px-4 rounded-lg">
                <h2 class="text-base font-medium md:text-lg text-gray-600">
                  Mode of Travel
                </h2>
                <select
                  name="count"
                  class="mt-4 block w-full pl-3 text-lg text-gray-600 py-4 border border-blue-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500  rounded-md"
                >
                  <option value="0" default selected>
                    Please Select
                  </option>

                  <option value="Airplane">Airplane</option>
                </select>
              </div>
              <div class="space-y-5 energy options mt-5 border border-blue-200 bg-blue-100 pt-6 pb-6 px-6 rounded-lg">
                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 pt-5 ">
                  <label
                    for="country"
                    class="block text-base font-medium md:text-lg text-gray-600"
                  >
                    Passengers
                  </label>

                  <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      name="state"
                      value={props.passengerNumber}
                      onChange={handleChange}
                      class="block w-full pl-3 text-lg text-gray-600 py-4 border border-blue-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                    >
                      <option value="0" default selected>
                        Please Select
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 pt-5">
                  <label
                    for="country"
                    class="block text-base font-medium md:text-lg text-gray-600"
                  >
                    From
                  </label>
                  <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <Autocomplete restrictions={restrictions} options={options}>
                      <input
                        type="text"
                        class="block w-full pl-3 text-lg text-gray-600 py-4 border border-blue-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                        ref={originRef}
                      />
                    </Autocomplete>
                  </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 pt-5">
                  <label
                    for="country"
                    class="block text-base font-medium md:text-lg text-gray-600"
                  >
                    To
                  </label>
                  <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <Autocomplete restrictions={restrictions} options={options}>
                      <input
                        class="block w-full pl-3 text-lg text-gray-600 py-4 border border-blue-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                        type="text"
                        ref={destiantionRef}
                      />
                    </Autocomplete>
                  </div>
                </div>

                <div>
                  <div role="group" aria-labelledby="label-notifications">
                    <div class="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                      <div>
                        <div
                          class="block text-base font-medium md:text-lg text-gray-600"
                          id="label-notifications"
                        >
                          Trip Type
                        </div>
                      </div>
                      <div class="sm:col-span-2">
                        <div class="max-w-lg">
                          <div class="mt-4 mb-4  space-y-4">
                            <div class="flex items-center">
                              <input
                                id="push-everything"
                                name="push-notifications"
                                onClick={() => {
                                  props.setRoundTrip(false);
                                }}
                                type="radio"
                                class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                for="push-everything"
                                class="ml-3 block text-sm font-medium text-gray-700"
                              >
                                One Way
                              </label>
                            </div>
                            <div class="flex items-center">
                              <input
                                id="push-email"
                                onClick={() => {
                                  props.setRoundTrip(true);
                                }}
                                name="push-notifications"
                                type="radio"
                                class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                for="push-email"
                                class="ml-3 block text-sm font-medium text-gray-700"
                              >
                                Round Trip
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="pt-8 justify-end flex">
                          <ButtonGroup>
                            <Button
                              onChange={props.addData}
                              onClick={calculateRoute}
                              class=" py-3 px-10 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-blue-800 hover:opacity-90 md:py-4 md:text-lg"
                            >
                              Calculate Route
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form class="checkout mt-5 md:pl-10 md:w-2/5 md:sticky md:top-5 ">
              <section class="mt-16 bg-gray-50 border border-emerald-500 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
                <div class="flex justify-center">
                  {" "}
                  <svg
                    class="h-7 md:h-8 mt-1 text-emerald-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM8.547 4.505a8.25 8.25 0 1011.672 8.214l-.46-.46a2.252 2.252 0 01-.422-.586l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.211.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.654-.261a2.25 2.25 0 01-1.384-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.279-2.132z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <h2 class="text-xl mt-1 ml-2 md:text-2xl text-emerald-600">
                    Carbon Footprint
                  </h2>
                </div>
                <dl class="mt-3 space-y-4">
                  {props.isRoundTrip === false ? (
                    <p class="text-center mx-auto pb-4 px-4 text-base md:text-lg text-gray-500">
                      Your purchase will eliminate{" "}
                      {isNaN(props.totalUsage) === true ||
                      props.isFormComplete === false ? (
                        <span class="text-emerald-600 font-bold"> 0 TONS </span>
                      ) : (
                        <span class="text-emerald-600 font-bold">
                          {" "}
                          {props.totalUsage.toFixed(2)} TONS{" "}
                        </span>
                      )}
                      of greenhouse gases per trip
                    </p>
                  ) : (
                    <p class="text-center mx-auto pb-4 px-4 text-base md:text-lg text-gray-500">
                      Your purchase will eliminate{" "}
                      {isNaN(props.totalUsage) === true ||
                      props.isFormComplete === false ? (
                        <span class="text-emerald-600 font-bold"> 0 TONS </span>
                      ) : (
                        <span class="text-emerald-600 font-bold">
                          {" "}
                          {props.roundTrip.toFixed(2)} TONS{" "}
                        </span>
                      )}
                      of greenhouse gases per trip
                    </p>
                  )}
                  <div class="border-t border-gray-200 pt-4 flex items-center justify-center">
                    <fieldset class="">
                      {props.isRoundTrip === false ? (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            {isNaN(props.totalUsage) === true ||
                            props.isFormComplete === false ? (
                              <p class="ml-3 block">
                                <span class="text-2xl font-bold text-emerald-900">
                                  $0/trip
                                </span>
                              </p>
                            ) : (
                              <p class="ml-3 block">
                                <span class="text-2xl font-bold text-emerald-900">
                                  ${props.finalOffset}/trip
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            {isNaN(props.totalUsage) === true ||
                            props.isFormComplete === false ? (
                              <p class="ml-3 block">
                                <span class="text-2xl font-bold text-emerald-900">
                                  $0/trip
                                </span>
                              </p>
                            ) : (
                              <p class="ml-3 block">
                                <span class="text-2xl font-bold text-emerald-900">
                                  ${props.roundTripOffset}/trip
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </fieldset>
                  </div>{" "}
                </dl>
                {isNaN(props.totalUsage) === true ||
                props.isFormComplete === false ? (
                  <div class="mt-7">
                    <button
                      type="button"
                      class="w-full pointer-events-none opacity-50 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                ) : (
                  <div class="mt-7">
                    <button
                      onClick={() => {
                        const newPosition = "PreCheckoutTravel";
                        props.onChange(newPosition);
                      }}
                      type="button"
                      class="w-full py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                )}
              </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Travel1;
