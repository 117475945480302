import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Travel1 from "../components/Travel1";
import PreCheckoutTravel from "../components/PreCheckoutTravel";
import CheckoutTravel from "../components/CheckoutTravel";
import Airplane from "../images/Airplane-HiRes.png";
import Favicon from "../images/logos/Gogreen-favicon.png"

const OffsetTravel = () => {
  var carbonOffset = 20.0;
  const [modulePosition, setmodulePosition] = useState("Travel1");

  const [inputData, setinputData] = useState({
    state: "0",
    commercialState: "0",
    priceType: "yearly",
    uetGreen: "false",
  });

  function dataChange(e) {
    setinputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  }

  const [distance, setDistance] = useState("");
  let numdistance = parseInt(distance.replace(/[^+\d]+/g, ""));
  const [PassengerCount, setPassengerCount] = useState(0);
  const [isRoundTrip, setisRoundTrip] = useState();
  const [isFormComplete, setisFormComplete] = useState(false);

  var currentStep = "";

  function positionChange(newValue) {
    setTimeout(function () {
      setmodulePosition(newValue);
    }, 1000);
  }
  function customerChange(e) {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
  }

  const [customerData, setCustomerData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    billingAddress: "",
    billingSuite: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    shippingAddress: "",
    shippingSuite: "",
    shippingCity: "",
    shippingState: "",
    shippingZip: "",
    email: "",
    phone: "",
    ext: "",
    referral: "",
  });

  ////////////////////////////////////////////////////////////////////////////

  // CO2 Math
  const totalUsage =
    PassengerCount * ((numdistance * 5 * 0.00957) / 210).toFixed(4);

  const roundTrip = totalUsage * 2;

  // Offset Math
  var finalOffset = Number((totalUsage * carbonOffset).toFixed(2));

  // Offset Round Trip Math
  var finalOffsetroundTrip = Number((roundTrip * carbonOffset).toFixed(2));

  ////////////////////////////////////////////////////////////////////////////

  switch (isRoundTrip) {
    case true:
      finalOffsetroundTrip = Number((roundTrip * carbonOffset).toFixed(2));
      break;
    case false:
      finalOffset = Number((totalUsage * carbonOffset).toFixed(2));
      break;
  }

  switch (modulePosition) {
    case "Travel1":
      currentStep = (
        <Travel1
          onChange={positionChange}
          addData={dataChange}
          data={inputData}
          distanceSet={setDistance}
          passenger={setPassengerCount}
          passengerNumber={PassengerCount}
          setRoundTrip={setisRoundTrip}
          isRoundTrip={isRoundTrip}
          isFormComplete={isFormComplete}
          setisFormComplete={setisFormComplete}
          totalUsage={totalUsage}
          finalOffset={finalOffset}
          roundTripOffset={finalOffsetroundTrip}
          roundTrip={roundTrip}
        />
      );

      break;
    case "PreCheckoutTravel":
      currentStep = (
        <PreCheckoutTravel
          onChange={positionChange}
          addData={dataChange}
          addCustomerData={customerChange}
          distanceSet={setDistance}
          passenger={setPassengerCount}
          passengerNumber={PassengerCount}
          setRoundTrip={setisRoundTrip}
          isRoundTrip={isRoundTrip}
          data={inputData}
          customer={customerData}
          finalOffset={finalOffset}
          totalUsage={totalUsage}
          roundTripOffset={finalOffsetroundTrip}
          roundTrip={roundTrip}
        />
      );
      break;
    case "CheckoutTravel":
      currentStep = (
        <CheckoutTravel
          onChange={positionChange}
          addData={dataChange}
          addCustomerData={customerChange}
          distanceSet={setDistance}
          passenger={setPassengerCount}
          passengerNumber={PassengerCount}
          setRoundTrip={setisRoundTrip}
          isRoundTrip={isRoundTrip}
          data={inputData}
          customer={customerData}
          finalOffset={finalOffset}
          totalUsage={totalUsage}
          roundTripOffset={finalOffsetroundTrip}
          roundTrip={roundTrip}
        />
      );
      break;
  }

  console.log(modulePosition);
  console.log(customerData);
  console.log(distance);
  console.log(PassengerCount);
  console.log(totalUsage);

  return (
    <>
      {modulePosition === "PreCheckoutTravel" ||
      modulePosition === "CheckoutTravel" ? (
        <div>
          <main class="pb-20 bg-white">
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                Go Green Credits | Travel Offset Calculator
              </title>

              <meta
                name="description"
                content="Go Green can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green! "
              />
              <link
                rel="icon"
                href={Favicon}
              />
            </Helmet>
            <Header />

            {currentStep}
          </main>
          <Footer />
        </div>
      ) : (
        <div>
          <main
            class="bg-cover"
            style={{ backgroundImage: `url(${Airplane})` }}
          >
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                Go Green Credits | Travel Offset Calculator
              </title>

              <meta
                name="description"
                content="Go Green can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green! "
              />
              <link
                rel="icon"
                href={Favicon}
              />
            </Helmet>
            <Header />
            {currentStep}
          </main>
          <Footer />
        </div>
      )}
    </>
  );
};

export default OffsetTravel;
