import React, { useState } from "react";

const PreCheckoutTravel = (props) => {
  const [shippingCheck, setShippingCheck] = useState(false);

  function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  }

  const emailCheck = validateEmail(props.customer.email);

  return (
    <div class="pre-checkout">
      <div class="hero mt-10 md:mt-20 items-center pb-10 px-4 md:px-0">
        <div class="mx-auto max-w-7xl">
          <div class="md:flex justify-between items-start">
            <div class="md:w-1/2">
              {" "}
              <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                Checkout
              </h1>
              <p class="mt-3 text-base text-gray-600 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                Now we just need some information from you
              </p>{" "}
              <div class="w-full car1 mt-8 pb-4 space-y-6 ">
                <div>
                  <div class="mt-12 flex items-center">
                    <svg
                      class="-mt-2 flex-shrink-0 text-emerald-700 h-8 w-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M3 18h-2c-.552 0-1-.448-1-1v-2h15v-9h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.121-.728 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-6c0 1.656-1.344 3-3 3s-3-1.344-3-3zm3-1.2c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm12 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-4-2.8h-14v-10c0-.552.448-1 1-1h12c.552 0 1 .448 1 1v10zm3-6v3h4.715l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.42z" />
                    </svg>
                    <h1 class="text-2xl ml-2 font-bold text-emerald-700">
                      Billing Details
                    </h1>
                  </div>
                  <div class="mt-4 flex justify-between ">
                    <div class="mt-1 relative rounded-md">
                      <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-orange"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        onChange={props.addCustomerData}
                        required
                        class="w-full pl-3 px-16 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                      />
                    </div>
                    <div class="mt-1 relative rounded-md">
                      <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-orange"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        onChange={props.addCustomerData}
                        required
                        class=" w-full pl-3 px-16 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                      />
                    </div>
                  </div>
                  <div class="mt-4 relative rounded-md">
                    <label
                      for="name"
                      class="mb-2 block text-sm font-medium text-orange"
                    >
                      Company Name (optional)
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      onChange={props.addCustomerData}
                      required
                      class=" w-full pl-3 px-16 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                    />
                  </div>{" "}
                  <div class="mt-4 relative rounded-md">
                    <label
                      for="name"
                      class="mb-2 block text-sm font-medium text-orange"
                    >
                      Billing Address
                    </label>
                    <input
                      type="text"
                      name="billingAddress"
                      onChange={props.addCustomerData}
                      required
                      class=" w-full pl-3 px-16 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                    />
                  </div>{" "}
                  <div class="mt-4 relative rounded-md">
                    <label
                      for="name"
                      class="mb-2 block text-sm font-medium text-orange"
                    >
                      Apartment, suite, etc.
                    </label>
                    <input
                      type="text"
                      name="billingSuite"
                      onChange={props.addCustomerData}
                      required
                      class=" w-full pl-3 px-16 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                    />
                  </div>{" "}
                  <div class="mt-4 flex justify-between gap-4  ">
                    <div class="mt-1 relative rounded-md">
                      <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-orange"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        name="billingCity"
                        onChange={props.addCustomerData}
                        required
                        class="w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                      />
                    </div>
                    <div class="mt-1 relative rounded-md">
                      <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-orange"
                      >
                        State
                      </label>

                      <select
                        name="billingState"
                        onChange={props.addCustomerData}
                        required
                        class="w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                      >
                        <option value="0" default selected>
                          Select State
                        </option>
                        <option disabled>------------------</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                        <option hidden>Wyomingsssssssssss</option>
                      </select>
                    </div>
                    <div class="mt-1 relative rounded-md">
                      <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-orange"
                      >
                        Zip Code
                      </label>
                      <input
                        type="number"
                        name="billingZip"
                        onChange={props.addCustomerData}
                        onWheel={(e) => e.target.blur()}
                        required
                        class=" w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                      />
                    </div>
                  </div>
                  <div class="mt-4 flex justify-between ">
                    <div class="mt-1 relative rounded-md">
                      <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-orange"
                      >
                        Phone
                      </label>
                      <input
                        type="number"
                        name="phone"
                        onChange={props.addCustomerData}
                        onWheel={(e) => e.target.blur()}
                        required
                        class="w-full pl-3 px-16 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                      />
                    </div>
                    <div class="mt-1 relative rounded-md">
                      <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-orange"
                      >
                        Ext.
                      </label>
                      <input
                        type="number"
                        name="ext"
                        onChange={props.addCustomerData}
                        onWheel={(e) => e.target.blur()}
                        required
                        class=" w-full pl-3 px-16 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                      />
                    </div>
                  </div>
                  <div class="mt-5 relative rounded-md">
                    <label
                      for="name"
                      class="mb-2 block text-sm font-medium text-orange"
                    >
                      How did you hear about us?
                    </label>

                    <select
                      name="referral"
                      onChange={props.addCustomerData}
                      required
                      class="w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                    >
                      <option value="Callective">Callective Energy</option>
                      <option value="UGE">United Green Energy</option>
                      <option value="Kratos">Kratos Gas & Power</option>
                      <option value="UF">Uncle Frank Energy</option>
                      <option value="Greenwave">Greenwave Energy</option>
                      <option value="UET"> United Energy Trading</option>
                      <option value="Columbine High" default selected>
                        Columbine High School
                      </option>
                      <option value="CT">Green-E</option>
                      <option value="DE">Other</option>
                    </select>
                  </div>
                  {emailCheck === false ? (
                    <div class="mt-5 relative rounded-md">
                      <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-orange"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        onChange={props.addCustomerData}
                        required
                        class=" w-full pl-3 px-16 text-lg text-gray-700 py-4 border border-red-600 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md"
                      />
                    </div>
                  ) : (
                    <div class="mt-5 relative rounded-md">
                      <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-orange"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        onChange={props.addCustomerData}
                        required
                        class=" w-full pl-3 px-16 text-lg text-gray-700 py-4 border border-emerald-600 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div class="flex justify-between">
                <div class="hidden mt-6 flex items-center h-5">
                  <input
                    name="shippingCheck"
                    type="checkbox"
                    onClick={() => setShippingCheck(!shippingCheck)}
                    class="focus:ring-orange h-4 w-4 text-orange border-gray-300 rounded"
                  />
                  <div class="ml-3 text-sm">
                    <label
                      for="house-energy-ng"
                      class="font-medium text-gray-700"
                    >
                      I have a different shipping address
                    </label>
                  </div>
                </div>

                <div class="mt-6 flex items-center h-5">
                  <a
                    href="/"
                    class="text-gray-500 underline font-medium text-sm hover:opacity-70"
                  >
                    Start Over/Reset Calculations
                  </a>
                </div>
              </div>
            </div>
            <form class="checkout mt-5 md:pl-10 md:w-2/5 md:sticky md:top-5 ">
              <section class="mt-16 bg-gray-50 border border-emerald-500 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
                {props.data.uetGreen === "true" ? (
                  <span class="mb-5 flex justify-center w-1/2 mx-auto py-1 rounded-full text-xs font-medium bg-emerald-100 text-green-800">
                    {" "}
                    <svg
                      class="mr-2 h-5 text-green-800"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h4 class=" text-sm font-medium text-green-800 mb-0">
                      Green Gas Customer
                    </h4>
                  </span>
                ) : (
                  <div></div>
                )}
                <div class="flex justify-center">
                  {" "}
                  {props.data.commercialState === "0" ? (
                    <svg
                      class="h-7 md:h-8 text-emerald-600"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM8.547 4.505a8.25 8.25 0 1011.672 8.214l-.46-.46a2.252 2.252 0 01-.422-.586l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.211.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.654-.261a2.25 2.25 0 01-1.384-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.279-2.132z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      class="h-7 md:h-8 text-emerald-600"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM8.547 4.505a8.25 8.25 0 1011.672 8.214l-.46-.46a2.252 2.252 0 01-.422-.586l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.211.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.654-.261a2.25 2.25 0 01-1.384-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.279-2.132z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  <h2 class="text-xl mt-1 ml-2 md:text-2xl text-emerald-600">
                    Carbon Footprint
                  </h2>
                </div>
                <dl class="mt-3 space-y-4">
                  {props.isRoundTrip === false ? (
                    <p class="text-center mx-auto pb-4 px-4 text-base md:text-lg text-gray-500">
                      Your purchase will eliminate{" "}
                      <span class="text-emerald-600 font-bold ">
                        {" "}
                        {props.totalUsage.toFixed(2)} TONS
                      </span>{" "}
                      of greenhouse gases Annually
                    </p>
                  ) : (
                    <p class="text-center mx-auto pb-4 px-4 text-base md:text-lg text-gray-500">
                      Your purchase will eliminate{" "}
                      <span class="text-emerald-600 font-bold ">
                        {" "}
                        {props.roundTrip.toFixed(2)} TONS
                      </span>{" "}
                      of greenhouse gases Annually
                    </p>
                  )}
                  <div class="border-t border-gray-200 pt-8 flex items-center justify-center">
                    <fieldset class="">
                      {props.isRoundTrip === false ? (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            <p class="ml-3 block">
                              <span class="text-2xl font-bold text-emerald-900">
                                ${props.finalOffset}/trip
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${props.roundTripOffset.toFixed(2)}
                              <span class="text-base font-medium">/trip</span>
                            </p>
                          </div>
                        </div>
                      )}
                    </fieldset>
                  </div>{" "}
                </dl>

                {props.customer.firstName === "" ||
                props.customer.lastName === "" ||
                props.customer.billingAddress === "" ||
                props.customer.billingCity === "" ||
                props.customer.billingState === "" ||
                props.customer.email === "" ||
                props.customer.billingAddress === "" ||
                emailCheck === false ? (
                  <div class="mt-7">
                    <button
                      type="button"
                      class="w-full pointer-events-none opacity-50 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                ) : (
                  <div class="mt-7">
                    <button
                      onClick={() => {
                        const newPosition = "CheckoutTravel";
                        props.onChange(newPosition);
                      }}
                      type="button"
                      class="w-full py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                )}
              </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreCheckoutTravel;
